<template>
	<div class="item" @click="navDetail()">
		<div class="row user">
			<van-image class="avatar" :src="item.avatar" fit="cover" round/>
			<div class="col user-info">
				<span class="nickname">{{ item.nickname }}</span>
				<span class="event" v-if="false">发布了问题</span>
			</div>
			<div class="row cc auth" v-if="item.is_cert == 1">
				<van-image class="auth-img" :src="require('@/assets/auth-1.png')" fit="cover" />
				<span>{{ item.major }}</span>
			</div>
		</div>
		<div class="date">{{ item.create_time_text }} 发布了新问题</div>
		<div class="row cb que-view">
			<div class="col que-left">
				<div class="que-title text-ellipsis-2">{{ item.title }}</div>
				<div class="row info">
					<span>{{ item.view_num }}浏览</span>
					<span>{{ item.focus_num }}关注</span>
					<span>{{ item.answer_num }}回答</span>
				</div>
			</div>
			<van-image v-if="item.image" class="que-img" :src="img(item.image)" fit="cover" />
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	props: {
		showUser: {
			type: Boolean,
			default: true
		},
		item: {
			type: Object,
			default: () => {
				return {};
			}
		}
	},
	computed: {
		img(val) {
			return val => {
				if (val == '') {
					return '';
				} else {
					return this.$store.getters.oss(val.split(',')[0]);
				}
			};
		}
	},
	data() {
		return {};
	},
	methods: {
		navDetail() {
			console.log(this.item)
			this.$router.push({
				path: '/ask/detail',
				query: { id: this.item.id }
			});
		}
	}
};
</script>

<style scoped lang="less">
@import './common.less';
</style>
